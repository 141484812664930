<template>
  <div>
    <!-- 搜索框 -->
    <van-search v-model="searchKey" placeholder="请输入搜索关键词" class="search-box" />
    <van-cell-group title="页面">
      <van-cell v-for="(item, index) of getPages" :key="index" :title="item.name" is-link :to="item.path"></van-cell>
    </van-cell-group>
  </div>
</template>

<script>
import PAGES from './pages.js'
export default {
  data() {
    return {
      PAGES,
      searchKey: '',
    }
  },
  computed: {
    getPages() {
      if (!this.searchKey) return this.PAGES
      return this.PAGES.filter(i => i.name.toLowerCase().includes(this.searchKey.toLowerCase()))
    }
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>

.search-box {
  box-shadow: 5px 5px 5px #eeeeeebb;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.upload-box {
  background-color: #fff;

  .input {
    padding: 20px;
  }
}
</style>
